import React from "react";
import {
  SiReact,
  SiGatsby,
  SiNextDotJs,
  SiFramer,
  SiNodeDotJs,
  SiHtml5,
  SiTypescript,
} from "react-icons/si";

type KnifeIconProps = {
  src: string;
};

const KnifeIcon = ({ src }: KnifeIconProps) => {
  return src === "React" ? (
    <SiReact />
  ) : src === "GatsbyJS" ? (
    <SiGatsby />
  ) : src === "NextJS" ? (
    <SiNextDotJs />
  ) : src === "Framer Motion" ? (
    <SiFramer />
  ) : src === "NodeJS" ? (
    <SiNodeDotJs />
  ) : src === "HTML 5" ? (
    <SiHtml5 />
  ) : src === "Typescript" ? (
    <SiTypescript />
  ) : null;
};

export default KnifeIcon;
