type knifePartsTypes = {
  id: number;
  name: string;
  svgMainPathD: string;
  originX: string | number;
  rotate: number;
  rotateExit: number;
  skill: string;
  secondaryClass?: string;
  svgSecondaryPathD?: string;
}[];

export const knifeParts: knifePartsTypes = [
  {
    id: 1,
    name: "corkscrew",
    svgMainPathD: `M130.8,200.3v2.7c0,1.4,1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5v-2.7c0-4.4,3.3-8,7.3-8s7.3,3.6,7.3,8v2.7c0,1.4,1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5v-2.7c0-4.4,3.3-8,7.3-8c3.5,0,6.6,2.9,7.2,6.7c0.2,1.4,1.5,2.3,2.8,2.1l30.1,0.2c3.9,0,7.1-3.2,7.1-7.1s-3.2-7.1-7.1-7.1l-40.2,0.2c-4,0-7.5,2-9.8,5.2c-2.2-3.1-5.8-5.2-9.8-5.2s-7.5,2-9.8,5.2c-2.2-3.1-5.8-5.2-9.8-5.2c-4,0-7.5,2-9.8,5.2c-2.2-3.1-5.8-5.2-9.8-5.2c-3.9,0-7.3,1.9-9.6,4.9c-1.5-1.7-5.6-5.4-10-3.1c0,0,6.2,2.4,6.2,12.8c0,0,0.2,3.2,3.1,3.5c0.2,0,0.4,0.1,0.6,0.1c1.4,0,2.5-1.1,2.5-2.5v-2.7c0-4.4,3.3-8,7.3-8s7.3,3.6,7.3,8v2.7c0,1.4,1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5v-2.7c0-4.4,3.3-8,7.3-8S130.8,195.9,130.8,200.3z`,
    originX: "56%",
    rotate: -45,
    rotateExit: 0,
    skill: "React",
  },
  {
    id: 2,
    name: "small-knife",
    svgMainPathD:
      "M205,192.1c0-3.8-3.1-7-7-7h-34.8l-6.9-2.5H80c0,0,17,16.4,58.2,16.4H198C201.9,199,205,195.9,205,192.1z",
    secondaryClass: "bevel",
    svgSecondaryPathD:
      "M136.3,190.8L136.3,190.8c0-1.9,1.5-3.4,3.4-3.4h8.2c1.9,0,3.4,1.5,3.4,3.4l0,0c0,1.9-1.5,3.4-3.4,3.4h-8.2C137.9,194.2,136.3,192.7,136.3,190.8z",
    originX: "56%",
    rotate: -90,
    rotateExit: 0,
    skill: "GatsbyJS",
  },
  {
    id: 3,
    name: "bottle-opener-right",
    svgMainPathD:
      "M171.1,198.8l31.4-20.5c0,0-1.1-7.2,7.1-7.2c8.2,0,17.6,0,17.6,0s8.4,0.7,8.4,8.8s-6.7,10.7-11,10.3c-3.8-0.4-6.2-3.7-6.2-3.7l-3,2.9c0,0,5.6,8.4,17.1,8c11.6-0.4,16-11.3,16-11.3h32.7c5,0,9-4,9-9s-4-9-9-9h-29.2c0,0-0.9-10.4-10.3-10.4H204C204,157.8,178.2,161.9,171.1,198.8z",
    secondaryClass: "bevel",
    svgSecondaryPathD:
      "M216.4,160.6h-14.5c0,0,1.9,4.5,7.3,4.5S216.4,160.6,216.4,160.6z",
    originX: "79%",
    rotate: 90,
    rotateExit: 4,
    skill: "NextJS",
  },
  {
    id: 4,
    name: "big-knife",
    svgMainPathD:
      "M281,160.3L281,160.3l-0.4,0c-0.2,0-0.4,0-0.6,0l-145.3,0.2c0,0-28.4,0.5-54.4,17.6c0,0,25.7,8.4,40.8,9.3s101.3,0.3,101.3,0.3s9.6-11,28.3-9.6c18.7,1.4,29.4,2.3,29.4,2.3s3.8,0.6,7.4-3c3.9-3.9,3.9-10.3,0-14.2C285.7,161.5,283.4,160.5,281,160.3z",
    secondaryClass: "bevel",
    svgSecondaryPathD:
      "M161.2,166.1l-32.4,0c4.7,4.6,10.2,7.3,16.2,7.3C151,173.4,156.5,170.7,161.2,166.1z",
    originX: "78%",
    rotate: 45,
    rotateExit: 0,
    skill: "Framer Motion",
  },
  {
    id: 5,
    name: "small-knife-top",
    svgMainPathD:
      "M87.9,189.5h74.6c0,0,18.1,0.4,34-12.3h0c0,0,0,0,0,0c0,0,0,0,0,0h0c-15.8-12.8-34-12.3-34-12.3H87.9c-6.8,0-12.3,5.5-12.3,12.3C75.5,184,81.1,189.5,87.9,189.5C87.9,189.5,87.9,189.5,87.9,189.5z",
    originX: 0,
    rotate: -45,
    rotateExit: 0,
    skill: "NodeJS",
  },
  {
    id: 6,
    name: "bottle-opener-left",
    svgMainPathD:
      "M147.9,188c-3,2.2-6.2,1.4-6.2,1.4c-6.1-10.7,6.1-16.9,9.4-17.4c3.3-0.5,30.6,9.7,30.6,9.7l19.2-6.4V166l-24.9-7.1h-56.5v5.8h3.2c0,0,3.5,0.1,3.5,3.4s-2.7,3.4-2.7,3.4H91.8c-5.5,0-9.9,4.4-9.9,9.9c0,5.5,4.4,9.9,9.9,9.9h38.6l6.6,5.4h5.1C147.4,196.7,147.9,188,147.9,188z",
    secondaryClass: "bevel",
    svgSecondaryPathD:
      "M167.2,162.7h-24.2c3.5,1.8,7.6,2.8,12.1,2.8C159.6,165.5,163.7,164.5,167.2,162.7z",
    originX: 0,
    rotate: -90,
    rotateExit: 0,
    skill: "Typescript",
  },
];
