import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

const HeroBannerImageStyles = styled.div`
  grid-column: 9 / span 5;
  height: 40rem;
  position: relative;
  @media ${(props) => props.theme.breakpoints.m} {
    height: 30rem;
    grid-column: 2 / span 6;
    .gatsby-image-wrapper {
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .image {
    position: relative;
    z-index: 1;
  }
  .image-bg {
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    div {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 26rem;
      height: 2.6rem;
      background: var(--color-black);
      @media ${(props) => props.theme.breakpoints.m} {
        width: 16rem;
      }
    }
  }
`;

type QueryTypes = {
  file: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

const HeroBannerImage = () => {
  const ImageStaticQuery = graphql`
    query ImageQuery {
      file(name: { eq: "mihai_bw" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP]
            width: 550
            quality: 90
            placeholder: NONE
          )
        }
      }
    }
  `;

  const data = useStaticQuery<QueryTypes>(ImageStaticQuery);

  const image = data.file.childImageSharp.gatsbyImageData;

  return (
    <HeroBannerImageStyles>
      <motion.div style={{ scale: 0.9 }} className="image">
        <GatsbyImage image={image} alt="Mihai Ursu" loading="eager" />
      </motion.div>
      <div className="image-bg">
        <motion.div
          initial={{ scaleX: 0, background: "#000000" }}
          style={{ translateX: "-50%", translateY: "-50%" }}
          animate={{
            scaleX: [0, 1, 1],
            rotate: [0, 0, 45],
            background: ["#000000", "#000000", "#F0F0F0"],
          }}
          transition={{ duration: 1, delay: 0.4 }}
        />
        <motion.div
          initial={{ scaleX: 0 }}
          style={{ translateX: "-50%", translateY: "-50%" }}
          animate={{
            scaleX: [0, 1, 1],
            rotate: [0, 0, -45],
            background: ["#000000", "#000000", "#F0F0F0"],
          }}
          transition={{ duration: 1, delay: 0.4 }}
        />
      </div>
    </HeroBannerImageStyles>
  );
};

export default HeroBannerImage;
