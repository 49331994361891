import React from "react";
import styled from "styled-components";

import AnimateUp from "./AnimateUp";
import AnimatedLink from "./AnimatedLink";
import HeroBannerImage from "./HeroBannerImage";

const HeroBannerStyles = styled.div`
  grid-column: 1 / span 14;
  grid-row: 1 / span 2;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, var(--column-width))) 1fr;
  gap: 0 2rem;
  margin: 0;
  z-index: 0;

  align-items: center;
  height: 100vh;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 1 / span 8;
    grid-template-columns: 2rem repeat(6, 1fr) 2rem;
    gap: 0 1rem;
    margin: 0;
    height: max-content;
    grid-row: 2;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    grid-template-columns: 1rem repeat(6, 1fr) 1rem;
    gap: 0 1rem;
    margin: 0;
  }

  .text-left {
    grid-column: 2 / span 6;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media ${(props) => props.theme.breakpoints.m} {
      grid-row: 2 / span 1;
      grid-column: 2 / span 6;
      align-items: center;
      margin-bottom: 3rem;
    }

    .hero-banner-title {
      font-size: var(--font-hero-size);
      line-height: var(--font-hero-line-height);
      margin: 0;
      @media ${(props) => props.theme.breakpoints.m} {
        font-size: var(--font-herotablet-size);
        line-height: var(--font-herotablet-line-height);
      }
    }

    .about {
      margin-bottom: 2.5rem;
    }

    .title-subtitle {
      margin-bottom: 1.2em;
    }

    .about,
    .title-subtitle {
      @media ${(props) => props.theme.breakpoints.m} {
        text-align: center;
      }
    }

    .gray-400 {
      color: var(--color-gray-600);
      margin: 0;
    }

    .subtitle {
      font-size: var(--font-herotablet-size);
      line-height: var(--font-herotablet-line-height);
      @media ${(props) => props.theme.breakpoints.m} {
        font-size: var(--font-title2-size);
        line-height: var(--font-title2-line-height);
      }
    }
  }

  .overlay {
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    background: var(--color-black);
  }
`;

const HeroBanner = () => {
  return (
    <HeroBannerStyles>
      <div className="text-left">
        <div className="mb-2">
          <AnimateUp delay={0.9}>
            <p className="highlight-title">Hello, my name is</p>
          </AnimateUp>
        </div>
        <div className="title-subtitle">
          <AnimateUp delay={0}>
            <h1 className="hero-banner-title">Mihai Ursu</h1>
          </AnimateUp>
          <AnimateUp delay={0.3}>
            <h1 className="gray-400 subtitle">Creative Web Developer</h1>
          </AnimateUp>
        </div>
        <div className="about">
          <AnimateUp delay={0.9}>
            <p>
              I am a front-end web developer currently residing in Romania. I
              love creating lightning-fast websites with cool animations. My
              main goal is to always provide an amazing user experience.
            </p>
          </AnimateUp>
        </div>
        <AnimateUp delay={0.9}>
          <AnimatedLink link="/contact" text="Let's Chat" />
        </AnimateUp>
      </div>
      <HeroBannerImage />
    </HeroBannerStyles>
  );
};

export default HeroBanner;
