import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import HeroBanner from "../components/HeroBanner";
import AboutMeSection from "../components/AboutMeSection";
import ProjectsSection from "../components/ProjectsSection";
import ContactSection from "../components/ContactSection";

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Swiss Army Web Developer" />

      <HeroBanner />
      <AboutMeSection />
      <ProjectsSection />
      <ContactSection formName="homePageForm" />
    </Layout>
  );
};

export default IndexPage;
