import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";
import ProjectCard from "./ProjectCard";

import type { Tags } from "../pages/projects";

const ProjectsSectionStyles = styled.section`
  grid-column: 2 / span 12;
  display: grid;
  grid-template-columns: repeat(12, minmax(auto, var(--column-width)));
  gap: 0 2rem;
  margin: 0;
  padding: 6em 0;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 2 / span 6;
    grid-template-columns: repeat(6, 1fr);
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    gap: 0 1rem;
    margin: 0;
  }

  .text-top {
    grid-column: 1 / span 12;
    margin-bottom: 1.5em;
    margin-bottom: 4rem;
    text-align: center;
    display: flex;
    justify-content: center;
    @media ${(props) => props.theme.breakpoints.m} {
      grid-column: 1 / span 6;
    }
    div {
      width: 30rem;
    }
  }
`;

type QueryTypes = {
  allSanityProject: {
    nodes: ProjectTypes[];
  };
};

type ProjectTypes = {
  cardImage: {
    asset: {
      localFile: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData;
        };
      };
    };
  };
  yOffset: number;
  _id: string;
  title: string;
  tags: Tags[];
  excerpt: string;
  slug: {
    current: string;
  };
};

const ProjectsSection = () => {
  // Queries
  const ProjectsStaticQuery = graphql`
    query MyQuery {
      allSanityProject(sort: { fields: projectOrder }) {
        nodes {
          cardImage {
            asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    width: 950
                    quality: 88
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          title
          tags {
            tag
            _key
          }
          excerpt
          slug {
            current
          }
          yOffset
          _id
        }
      }
    }
  `;

  const data = useStaticQuery<QueryTypes>(ProjectsStaticQuery);

  return (
    <ProjectsSectionStyles>
      <div className="text-top">
        <div>
          <h1>My Projects</h1>
          <p>
            Here is a collection of some of my favorite projects I've designed
            and developed recently. It's a pleasure to share them with you.
          </p>
        </div>
      </div>
      {data.allSanityProject.nodes.map((project: ProjectTypes) => (
        <ProjectCard
          key={project._id}
          yOffset={project.yOffset}
          slug={project.slug.current}
          excerpt={project.excerpt}
          title={project.title}
          tags={project.tags}
          image={
            project.cardImage.asset.localFile.childImageSharp.gatsbyImageData
          }
        />
      ))}
    </ProjectsSectionStyles>
  );
};

export default ProjectsSection;
