import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const HoveredTextStyles = styled.span`
  position: relative;
  display: inline-block;
  text-decoration: underline;
  color: var(--color-black);
  .tooltip {
    position: absolute;
    left: 50%;
    top: -110%;
    background: var(--color-gray-900-transparent);
    padding: 0 0.6em;
    width: max-content;
    color: var(--color-gray-100);
    &:after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -6px;
      width: 0;
      height: 0;
      border-top: solid 6px var(--color-gray-900-transparent);
      border-left: solid 6px transparent;
      border-right: solid 6px transparent;
    }
  }
  .hovered-text {
    cursor: pointer;
  }
`;

type TextTypes = {
  text: string;
  hoverText: string;
};

const HoveredText = ({ text, hoverText }: TextTypes) => {
  const [hovered, toggleHovered] = useState(false);

  return (
    <HoveredTextStyles>
      <motion.span
        style={{ translateX: "-50%" }}
        initial={{ opacity: 0, y: -6 }}
        animate={{
          opacity: hovered ? 1 : 0,
          y: hovered ? 0 : -6,
          display: hovered ? "inline-block" : "none",
        }}
        transition={{ duration: 0.2 }}
        className="tooltip"
      >
        {hoverText}
      </motion.span>
      <motion.span
        onHoverStart={() => toggleHovered(true)}
        onHoverEnd={() => toggleHovered(false)}
        className="hovered-text bold"
      >
        {text}
      </motion.span>
    </HoveredTextStyles>
  );
};

export default HoveredText;
