import React from "react";
import styled from "styled-components";
import { BsCaretRightFill } from "react-icons/bs";
import HoveredText from "./HoveredText";
import Knife from "./Knife";

const AboutMeSectionStyles = styled.section`
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, var(--column-width))) 1fr;
  gap: 0 2rem;
  margin: 0;

  padding: 6em 0;
  background-color: var(--color-white);

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 1 / span 8;
    grid-template-columns: 2rem repeat(6, 1fr) 2rem;
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    grid-template-columns: 1rem repeat(6, 1fr) 1rem;
    gap: 0 1rem;
    margin: 0;
  }

  .text-left {
    grid-column: 2 / span 5;
    @media ${(props) => props.theme.breakpoints.m} {
      grid-column: 2 / span 6;
      text-align: center;
      margin-bottom: 2rem;
    }

    p {
      margin-bottom: 1em;
    }

    .techs {
      display: flex;
      @media ${(props) => props.theme.breakpoints.m} {
        text-align: left;
        justify-content: space-evenly;
      }
      .right-column {
        ul {
          margin-right: 0;
        }
      }
      ul {
        margin-right: 4rem;
        @media ${(props) => props.theme.breakpoints.m} {
          margin-right: 3rem;
        }
        li {
          font-size: var(--font-small-size);
          svg {
            margin-bottom: -0.14em;
            fill: var(--color-black);
          }
        }
      }
    }
  }

  .animated-graphic {
    grid-column: 2 / span 12;
    margin-top: 4rem;
    text-align: center;
  }

  .image-right {
    grid-column: 9 / span 5;
    @media ${(props) => props.theme.breakpoints.m} {
      grid-column: 3 / span 4;
    }
    @media ${(props) => props.theme.breakpoints.s} {
      grid-column: 2 / span 6;
    }
  }
`;

const AboutMeSection = () => {
  return (
    <AboutMeSectionStyles id="about-me">
      <div className="text-left">
        <h1>About Me</h1>
        <p>
          Hello! My name is Mihai, and I like to think of myself as the{" "}
          <span className="bold">swiss army knife</span> of web development.
          I've loved coding websites for a{" "}
          <HoveredText
            text="long time"
            hoverText="sliced them in Dreamweaver :("
          />
          , but only recently jumped on the Jamstack bandwagon.
        </p>
        <p>
          With over ten years of background in design, I do my best to create
          the cleanest-looking websites. I always aim to develop websites with
          clear and maintainable code.
        </p>
        <p>Here are a few technologies that I like to use:</p>
        <div className="techs">
          <div className="left-column">
            <ul>
              <li>
                <BsCaretRightFill /> HTML5
              </li>
              <li>
                <BsCaretRightFill /> Javascript ES5+
              </li>
              <li>
                <BsCaretRightFill /> GastbyJs
              </li>
              <li>
                <BsCaretRightFill /> Next.js
              </li>
              <li>
                <BsCaretRightFill /> Framer Motion
              </li>
              <li>
                <BsCaretRightFill /> GSAP
              </li>
            </ul>
          </div>
          <div className="right-column">
            <ul>
              <li>
                <BsCaretRightFill /> Figma
              </li>
              <li>
                <BsCaretRightFill /> CSS-in-JS
              </li>
              <li>
                <BsCaretRightFill /> GraphQL
              </li>
              <li>
                <BsCaretRightFill /> Node.js
              </li>

              <li>
                <BsCaretRightFill /> MongoDB
              </li>
              <li>
                <BsCaretRightFill /> TypeScript
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="image-right">
        <Knife />
      </div>
      {/* <div className="animated-graphic">
        <h2>Past Experience</h2>
      </div> */}
    </AboutMeSectionStyles>
  );
};

export default AboutMeSection;
