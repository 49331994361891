import React, { useState } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { knifeParts } from "../data/knife";
import KnifeIcon from "./KnifeIcon";

const KnifeStyles = styled.div`
  height: 100%;
  position: relative;

  .skill {
    position: absolute;
    z-index: 2;
    text-align: center;
    width: 70%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    pointer-events: none;
    overflow: hidden;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      h2 {
        color: var(--color-white);
        margin: 0;
        user-select: none;
        font-size: var(--font-title3-size);
        line-height: var(--font-title3-line-height);
        @media ${(props) => props.theme.breakpoints.s} {
          font-size: var(--font-base-size);
          line-height: var(--font-small-line-height);
        }
      }
      svg {
        width: 2em;
        height: 2em;
        margin-right: 0.5em;
        fill: var(--color-white);
        @media ${(props) => props.theme.breakpoints.s} {
          width: 1.4em;
          height: 1.4em;
        }
      }
    }
  }

  .knife-background {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;

    .click-me {
      path {
        fill: var(--color-gray-200);
      }
      .click-me-text {
        path {
          fill: var(--color-black);
        }
      }
    }

    .gradient-bg {
      fill: url(#header-shape-gradient) #fff;
    }

    #header-shape-gradient {
      --color-stop: var(--color-gray-100);
      --color-bot: var(--color-white);
    }
  }

  .knife {
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
    z-index: 1;

    .holder {
      fill: transparent;
      stroke-width: 4px;
      stroke: var(--color-black);
    }

    .knife-stroke {
      fill: var(--color-gray-100);
    }

    .bevel {
      fill: var(--color-gray-400);
    }

    .knife-body {
      fill: var(--color-black);
    }
    .knife-body-dark {
      fill: var(--color-gray-900);
    }
    .cross {
      fill: var(--color-white);
    }
    .knife-shine {
      fill: var(--color-white);
    }

    .knife-body-bg {
      transform: translateY(6px);
      fill: var(--color-gray-600);
    }

    .small-knife,
    .corkscrew,
    .bottle-opener-right,
    .big-knife,
    .small-knife-top,
    .bottle-opener-left {
      fill: var(--color-gray-200);
    }
  }
`;

const Knife = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleClick = () => {
    if (currentSlide < knifeParts.length - 1) {
      setCurrentSlide(currentSlide + 1);
    } else {
      setCurrentSlide(0);
    }
  };

  return (
    <KnifeStyles>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 359 361.6"
        className="knife-background"
      >
        <defs>
          <linearGradient id="header-shape-gradient" x2="0.35" y2="1">
            <stop offset="0%" stopColor="var(--color-stop)" />
            <stop offset="30%" stopColor="var(--color-stop)" />
            <stop offset="100%" stopColor="var(--color-bot)" />
          </linearGradient>
        </defs>
        <circle className="gradient-bg" cx="180" cy="180" r="152" />

        <motion.g
          animate={{ rotate: 360 }}
          transition={{ repeat: Infinity, duration: 12, ease: "linear" }}
          className="click-me"
        >
          <g>
            <path
              d="M140.1,342.4c-0.1,0-0.2,0-0.2,0c-35.6-8.8-67.9-29.6-90.9-58.5C25.3,254.1,12.8,218.1,12.8,180
			c0-38.1,12.5-74,36.1-103.8c22.9-28.9,55.2-49.7,90.8-58.5c0.5-0.1,1.1,0.2,1.2,0.7c0.1,0.5-0.2,1.1-0.7,1.2
			C66.4,37.9,14.8,103.8,14.8,180c0,76.2,51.6,142.2,125.5,160.4c0.5,0.1,0.9,0.7,0.7,1.2C140.9,342.1,140.5,342.4,140.1,342.4z"
            />
          </g>
          <g>
            <path
              d="M218.7,342.7c-0.5,0-0.9-0.3-1-0.8c-0.1-0.5,0.2-1.1,0.7-1.2c74.6-17.8,126.7-83.9,126.7-160.7
			c0-76.4-51.8-142.4-125.9-160.5c-0.5-0.1-0.9-0.7-0.7-1.2s0.7-0.9,1.2-0.7c35.7,8.7,68.1,29.5,91.2,58.4
			c23.7,29.8,36.3,65.8,36.3,104c0,38.4-12.6,74.4-36.5,104.3c-23.2,29-55.8,49.7-91.7,58.3C218.9,342.7,218.8,342.7,218.7,342.7z"
            />
          </g>
          <g className="click-me-text">
            <path
              d="M203.3,339.6c0.8-0.1,1.4-0.1,2,0.1c0.6,0.2,1,0.5,1.4,1c0.4,0.5,0.6,1,0.7,1.7c0.1,0.3,0.1,0.7,0.2,1.1s0.1,0.8,0.1,1.1
			c0.1,0.7,0,1.3-0.2,1.9s-0.6,1-1.1,1.3c-0.5,0.3-1.1,0.6-1.9,0.7c-0.6,0.1-1.1,0.1-1.5,0c-0.5-0.1-0.9-0.2-1.2-0.4
			s-0.6-0.5-0.9-0.8c-0.2-0.3-0.4-0.7-0.4-1.1c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.1,0.2-0.1l1.2-0.2c0.1,0,0.2,0,0.2,0
			c0.1,0,0.1,0.1,0.2,0.2c0.2,0.5,0.5,0.9,0.8,1s0.8,0.2,1.2,0.1c0.5-0.1,1-0.3,1.2-0.7s0.4-0.9,0.3-1.6c-0.1-0.7-0.2-1.4-0.3-2.1
			c-0.1-0.7-0.4-1.2-0.7-1.4c-0.4-0.3-0.8-0.3-1.4-0.3c-0.5,0.1-0.9,0.2-1.2,0.5c-0.3,0.2-0.5,0.6-0.5,1.2c0,0.1,0,0.2-0.1,0.3
			c-0.1,0-0.1,0.1-0.2,0.1l-1.2,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.1-0.2c-0.1-0.4,0-0.8,0.1-1.2c0.1-0.4,0.3-0.7,0.6-1
			s0.6-0.6,1-0.8C202.2,339.8,202.7,339.6,203.3,339.6z"
            />
            <path
              d="M198,340.4c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2l0.8,8.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1l-1.2,0.1
			c-0.1,0-0.2,0-0.2-0.1s-0.1-0.1-0.1-0.2l-0.7-6.9l-3.9,0.4c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.2l-0.1-0.9
			c0-0.1,0-0.2,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1L198,340.4z"
            />
            <path
              d="M190.9,341c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2l0.5,8.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1l-1.2,0.1
			c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.2l-0.5-8.1c0-0.1,0-0.2,0.1-0.2c0-0.1,0.1-0.1,0.2-0.1L190.9,341z"
            />
            <path
              d="M184.2,341.2c0.8,0,1.4,0.1,2,0.4c0.5,0.3,1,0.6,1.3,1.1c0.3,0.5,0.5,1.1,0.5,1.8c0,0.3,0,0.7,0,1.1c0,0.4,0,0.8,0,1.1
			c0,0.7-0.2,1.3-0.4,1.8c-0.3,0.5-0.7,0.9-1.2,1.2s-1.2,0.4-1.9,0.5c-0.6,0-1.1,0-1.5-0.2c-0.5-0.1-0.8-0.3-1.2-0.6
			c-0.3-0.3-0.6-0.6-0.8-0.9c-0.2-0.3-0.3-0.7-0.3-1.1c0-0.1,0-0.1,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1l1.2,0c0.1,0,0.2,0,0.2,0.1
			c0.1,0,0.1,0.1,0.1,0.2c0.1,0.5,0.4,0.9,0.7,1.1s0.7,0.3,1.2,0.3c0.6,0,1-0.2,1.3-0.5c0.3-0.3,0.5-0.8,0.5-1.5
			c0-0.7,0-1.4-0.1-2.1c0-0.7-0.2-1.2-0.6-1.5c-0.3-0.3-0.8-0.4-1.3-0.4c-0.5,0-0.9,0.1-1.2,0.3c-0.3,0.2-0.5,0.6-0.6,1.1
			c0,0.1-0.1,0.2-0.1,0.2c-0.1,0-0.1,0.1-0.2,0.1l-1.2,0c-0.1,0-0.1,0-0.2-0.1s-0.1-0.1-0.1-0.2c0-0.4,0.1-0.8,0.2-1.1
			c0.2-0.4,0.4-0.7,0.7-0.9c0.3-0.3,0.7-0.5,1.1-0.6C183.1,341.3,183.6,341.2,184.2,341.2z"
            />
            <path
              d="M178.9,341.4c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2l-0.2,8.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1l-1.1,0
			c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.2l0.1-2.9l-2.9,2.9c0,0-0.1,0.1-0.2,0.1s-0.2,0.1-0.3,0.1l-1.2,0
			c-0.1,0-0.1,0-0.2-0.1s-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0.1-0.1l3.5-3.7l-3.6-4.2c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0.1-0.2
			s0.1-0.1,0.2-0.1l1.3,0c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.1,0.2,0.1l2.9,3.4l0.1-3.2c0-0.1,0-0.2,0.1-0.2s0.1-0.1,0.2-0.1
			L178.9,341.4z"
            />
            <path
              d="M168.4,341c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2l-0.8,8.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1l-1-0.1
			c-0.1,0-0.2-0.1-0.3-0.1s-0.1-0.1-0.1-0.2l-2-4.7l-2.8,4.2c0,0-0.1,0.1-0.1,0.1s-0.2,0.1-0.3,0.1l-1-0.1c-0.1,0-0.2,0-0.2-0.1
			c-0.1-0.1-0.1-0.1-0.1-0.2l0.8-8.1c0-0.1,0-0.2,0.1-0.2s0.1-0.1,0.2-0.1l1,0.1c0.1,0,0.2,0,0.2,0.1c0,0.1,0.1,0.1,0.1,0.2
			l-0.5,5.4l2-3.1c0-0.1,0.1-0.1,0.2-0.2s0.2-0.1,0.3-0.1l0.5,0c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.1,0.1,0.2l1.4,3.5l0.5-5.4
			c0-0.1,0-0.2,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1L168.4,341z"
            />
            <path
              d="M158.5,339.9c0.1,0,0.1,0.1,0.2,0.1c0,0.1,0.1,0.1,0.1,0.2l-1.2,8c0,0.1-0.1,0.2-0.1,0.2c-0.1,0-0.1,0.1-0.2,0.1l-5.4-0.8
			c-0.1,0-0.2-0.1-0.2-0.1s-0.1-0.1-0.1-0.2l0.1-0.8c0-0.1,0.1-0.1,0.1-0.2c0.1,0,0.1-0.1,0.2-0.1l4,0.6l0.3-2.2l-3.8-0.6
			c-0.1,0-0.2-0.1-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.1-0.8c0-0.1,0.1-0.2,0.1-0.2s0.1-0.1,0.2,0l3.8,0.6l0.3-2.2l-4.1-0.6
			c-0.1,0-0.2-0.1-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.1-0.8c0-0.1,0.1-0.1,0.1-0.2c0.1,0,0.1-0.1,0.2-0.1L158.5,339.9z"
            />
          </g>
          <g className="click-me-text">
            <path
              d="M156.7,20.4c-0.8,0.1-1.4,0.1-2-0.1c-0.6-0.2-1-0.5-1.4-1c-0.4-0.5-0.6-1-0.7-1.7c-0.1-0.3-0.1-0.7-0.2-1.1
			c-0.1-0.4-0.1-0.8-0.1-1.1c-0.1-0.7,0-1.3,0.2-1.9c0.2-0.5,0.6-1,1.1-1.3c0.5-0.3,1.1-0.6,1.9-0.7c0.6-0.1,1.1-0.1,1.5,0
			s0.9,0.2,1.2,0.4c0.3,0.2,0.6,0.5,0.9,0.8c0.2,0.3,0.4,0.7,0.4,1.1c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.1-0.2,0.1l-1.2,0.2
			c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.2c-0.2-0.5-0.5-0.9-0.8-1c-0.3-0.2-0.8-0.2-1.2-0.1c-0.5,0.1-1,0.3-1.2,0.7
			c-0.3,0.4-0.4,0.9-0.3,1.6c0.1,0.7,0.2,1.4,0.3,2.1c0.1,0.7,0.4,1.2,0.7,1.4c0.4,0.3,0.8,0.4,1.4,0.3c0.5-0.1,0.9-0.2,1.2-0.5
			c0.3-0.2,0.5-0.6,0.5-1.2c0-0.1,0-0.2,0.1-0.3c0.1,0,0.1-0.1,0.2-0.1l1.2-0.2c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0.1,0.1,0.2
			c0,0.4,0,0.8-0.1,1.2c-0.1,0.4-0.3,0.7-0.6,1c-0.3,0.3-0.6,0.6-1,0.8S157.3,20.4,156.7,20.4z"
            />
            <path
              d="M162,19.6c-0.1,0-0.2,0-0.2-0.1s-0.1-0.1-0.1-0.2l-0.8-8.1c0-0.1,0-0.2,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1l1.2-0.1
			c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2l0.7,6.9l3.9-0.4c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2l0.1,0.9
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1L162,19.6z"
            />
            <path
              d="M169.1,19c-0.1,0-0.2,0-0.2-0.1s-0.1-0.1-0.1-0.2l-0.5-8.1c0-0.1,0-0.2,0.1-0.2s0.1-0.1,0.2-0.1l1.2-0.1
			c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2l0.5,8.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1L169.1,19z"
            />
            <path
              d="M175.8,18.8c-0.8,0-1.4-0.1-2-0.4s-1-0.6-1.3-1.1c-0.3-0.5-0.5-1.1-0.5-1.8c0-0.3,0-0.7,0-1.1c0-0.4,0-0.8,0-1.1
			c0-0.7,0.2-1.3,0.4-1.8c0.3-0.5,0.7-0.9,1.2-1.2s1.2-0.4,1.9-0.5c0.6,0,1.1,0,1.5,0.2c0.5,0.1,0.8,0.3,1.2,0.6
			c0.3,0.3,0.6,0.6,0.8,0.9c0.2,0.3,0.3,0.7,0.3,1.1c0,0.1,0,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1l-1.2,0c-0.1,0-0.2,0-0.2-0.1
			s-0.1-0.1-0.1-0.2c-0.1-0.5-0.4-0.9-0.7-1.1c-0.3-0.2-0.7-0.3-1.2-0.3c-0.6,0-1,0.2-1.3,0.5c-0.3,0.3-0.5,0.8-0.5,1.5
			c0,0.7,0,1.4,0.1,2.1c0,0.7,0.2,1.2,0.6,1.5c0.3,0.3,0.8,0.4,1.3,0.4c0.5,0,0.9-0.1,1.2-0.3c0.3-0.2,0.5-0.6,0.6-1.1
			c0-0.1,0.1-0.2,0.1-0.2c0.1,0,0.1-0.1,0.2-0.1l1.2,0c0.1,0,0.1,0,0.2,0.1s0.1,0.1,0.1,0.2c0,0.4-0.1,0.8-0.2,1.1s-0.4,0.7-0.7,0.9
			c-0.3,0.3-0.7,0.5-1.1,0.6C176.9,18.7,176.4,18.8,175.8,18.8z"
            />
            <path
              d="M181.1,18.6c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.2l0.2-8.1c0-0.1,0-0.2,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1l1.1,0
			c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2l-0.1,2.9l2.9-2.9c0,0,0.1-0.1,0.2-0.1c0.1-0.1,0.2-0.1,0.3-0.1l1.2,0
			c0.1,0,0.1,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.1-0.1,0.1l-3.5,3.7l3.6,4.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.1-0.1,0.2
			s-0.1,0.1-0.2,0.1l-1.3,0c-0.1,0-0.2,0-0.3-0.1s-0.1-0.1-0.2-0.1l-2.9-3.4l-0.1,3.2c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1
			L181.1,18.6z"
            />
            <path
              d="M191.6,19c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.2l0.8-8.1c0-0.1,0-0.2,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1l1,0.1
			c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.1,0.1,0.1,0.2l2,4.7l2.8-4.2c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.1,0.3-0.1l1,0.1
			c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2l-0.8,8.1c0,0.1,0,0.2-0.1,0.2s-0.1,0.1-0.2,0.1l-1-0.1c-0.1,0-0.2,0-0.2-0.1
			c0-0.1-0.1-0.1-0.1-0.2l0.5-5.4l-2,3.1c0,0.1-0.1,0.1-0.2,0.2s-0.2,0.1-0.3,0.1l-0.5,0c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.1-0.1-0.1-0.1-0.2l-1.4-3.5l-0.5,5.4c0,0.1,0,0.2-0.1,0.2s-0.1,0.1-0.2,0.1L191.6,19z"
            />
            <path
              d="M201.5,20.1c-0.1,0-0.1-0.1-0.2-0.1s-0.1-0.1-0.1-0.2l1.2-8c0-0.1,0.1-0.2,0.1-0.2c0.1,0,0.1-0.1,0.2-0.1l5.4,0.8
			c0.1,0,0.2,0.1,0.2,0.1s0.1,0.1,0.1,0.2l-0.1,0.8c0,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.1,0.1-0.2,0.1l-4-0.6l-0.3,2.2l3.8,0.6
			c0.1,0,0.2,0.1,0.2,0.1c0,0.1,0.1,0.1,0.1,0.2l-0.1,0.8c0,0.1-0.1,0.2-0.1,0.2c-0.1,0-0.1,0.1-0.2,0l-3.8-0.6l-0.3,2.2l4.1,0.6
			c0.1,0,0.2,0.1,0.2,0.1s0.1,0.1,0.1,0.2l-0.1,0.8c0,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.1,0.1-0.2,0.1L201.5,20.1z"
            />
          </g>
        </motion.g>
      </svg>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 359 361.6"
        className="knife"
        onClick={handleClick}
      >
        <path
          className="knife-body-bg"
          d="M284,207.9H92.6c-14.9,0-27.1-12.2-27.1-27.1v0c0-14.9,12.2-27.1,27.1-27.1H284c14.9,0,27.1,12.2,27.1,27.1v0
			C311.1,195.7,298.9,207.9,284,207.9z"
        />
        <AnimatePresence exitBeforeEnter>
          {knifeParts.map(
            (part, i) =>
              currentSlide === i && (
                <motion.g
                  key={part.id}
                  style={{ originX: part.originX }}
                  animate={{ rotate: part.rotate }}
                  exit={{ rotate: part.rotateExit }}
                >
                  <path className={part.name} d={part.svgMainPathD} />
                  {part.svgSecondaryPathD && (
                    <path
                      className={part.secondaryClass}
                      d={part.svgSecondaryPathD}
                    />
                  )}
                </motion.g>
              )
          )}
        </AnimatePresence>

        <path
          className="knife-stroke"
          d="M284,211.4H92.6c-16.9,0-30.6-13.7-30.6-30.6c0-16.9,13.7-30.6,30.6-30.6H284c16.9,0,30.6,13.7,30.6,30.6
				C314.6,197.6,300.9,211.4,284,211.4z M92.6,157.2c-13,0-23.6,10.6-23.6,23.6s10.6,23.6,23.6,23.6H284c13,0,23.6-10.6,23.6-23.6
				S297,157.2,284,157.2H92.6z"
        />
        <g>
          <path
            className="knife-body"
            d="M284,207.9H92.6c-14.9,0-27.1-12.2-27.1-27.1v0c0-14.9,12.2-27.1,27.1-27.1H284c14.9,0,27.1,12.2,27.1,27.1v0
			C311.1,195.7,298.9,207.9,284,207.9z"
          />
          <path
            className="knife-body-dark"
            d="M284,207.9c14.9,0,27.1-12.2,27.1-27.1c0-14.9-12.2-27.1-27.1-27.1h-73.6c0,0-4.7,24.9,18.5,31.4
	c23.2,6.5,20.5,22.8,20.5,22.8H284z"
          />
          <path
            className="cross"
            d="M288.1,178.1H286V176c0-1.5-1.2-2.7-2.7-2.7s-2.7,1.2-2.7,2.7v2.2h-2.2c-1.5,0-2.7,1.2-2.7,2.7v0c0,1.5,1.2,2.7,2.7,2.7h2.2
	v2.2c0,1.5,1.2,2.7,2.7,2.7s2.7-1.2,2.7-2.7v-2.2h2.2c1.5,0,2.7-1.2,2.7-2.7v0C290.8,179.3,289.6,178.1,288.1,178.1z"
          />
        </g>

        <circle className="holder" cx="62" cy="180.8" r="6" />
      </svg>

      <div className="skill">
        <AnimatePresence exitBeforeEnter>
          {knifeParts.map(
            (part, i) =>
              currentSlide === i && (
                <motion.div
                  key={part.id}
                  initial={{ y: 50 }}
                  animate={{ y: 0 }}
                  exit={{ y: -50 }}
                >
                  <KnifeIcon src={part.skill} />
                  <h2>{part.skill}</h2>
                </motion.div>
              )
          )}
        </AnimatePresence>
      </div>
    </KnifeStyles>
  );
};

export default Knife;
