import React from "react";
import styled from "styled-components";
import { IGatsbyImageData } from "gatsby-plugin-image";
import ProjectTag from "./ProjectTag";
import AnimatedLink from "./AnimatedLink";
import ParallaxImage from "./ParallaxImage";
import { motion } from "framer-motion";
import { Link } from "gatsby";

import type { Tags } from "../pages/projects";

const ProjectCardStyles = styled.div`
  grid-column: 1 / span 12;
  display: grid;
  grid-template-columns: repeat(12, minmax(auto, var(--column-width)));
  gap: 0 2rem;
  margin: 0;
  margin-bottom: 10rem;
  &:last-child {
    margin-bottom: 0;
  }

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 1 / span 6;
    grid-template-columns: repeat(6, 1fr);
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    gap: 0 1rem;
    margin: 0;
  }

  a {
    text-decoration: none;
  }

  .gatsby-image-wrapper {
    @media ${(props) => props.theme.breakpoints.m} {
      transform: translateY(-2em);
    }
  }

  .image-link {
    grid-column-end: span 12;
    display: flex;
    justify-content: center;
    @media ${(props) => props.theme.breakpoints.m} {
      grid-column-end: span 6;
    }
  }

  .text {
    grid-column-end: span 12;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: right;
    @media ${(props) => props.theme.breakpoints.m} {
      grid-column-end: span 6;
    }
    h2 {
      margin-bottom: 0.24em;
      font-size: var(--font-hero-size);
      line-height: var(--font-hero-line-height);
      @media ${(props) => props.theme.breakpoints.m} {
        font-size: var(--font-herophone-size);
        line-height: var(--font-herophone-line-height);
      }
    }
    .tags {
      display: flex;
      margin-bottom: 2em;
    }
    .excerpt {
      width: 36em;
      border-radius: 0.5em;
      text-align: center;
      margin-bottom: 4em;
      @media ${(props) => props.theme.breakpoints.m} {
        width: auto;
      }
    }
  }
`;

type ProjectData = {
  excerpt: string;
  title: string;
  slug: string;
  tags: Tags[];
  image: IGatsbyImageData;
  yOffset: number;
};

const ProjectCard = ({
  title,
  tags,
  slug,
  image,
  excerpt,
  yOffset,
}: ProjectData) => {
  return (
    <ProjectCardStyles>
      <Link to={`/projects/${slug}`} className="image-link">
        <ParallaxImage image={image} yOffset={yOffset} alt={title} />
      </Link>

      <motion.div className="text">
        <h2>{title}</h2>
        <div className="tags">
          {tags.map((tag) => (
            <ProjectTag tag={tag.tag} key={tag._key} />
          ))}
        </div>
        <p className="excerpt">{excerpt}</p>
        <AnimatedLink link={`/projects/${slug}`} text="See Project" />
      </motion.div>
    </ProjectCardStyles>
  );
};

export default ProjectCard;
